import React,{useEffect, useState} from 'react';
import "./abc-digital-title.scss";

const BlogTitle = () => {

    const [pageSetting, setPageSetting] = useState({categories: [], tags: []});

    useEffect(() => {
        // Obtengo todas las clases css de body.
        const bodyClasses = [...document.body.classList];

        // Busco todos los items que tengan la palabra category-.
        let categories = bodyClasses.find(item => item.includes('category-'));

        if(categories != undefined){
            categories = categories.split("-")[1];
        }

        // Busco todos los items que tengan la palabra tag-.
        let tags = bodyClasses.find(item => item.includes('tag-'));

        if(tags != undefined){
            tags = tags.split("-")[1];
        }

        const data = {categories: categories, tags: tags};

        setPageSetting(data);

    }, []);

    return ( 
        <>
            <section className="BlogTitle">
                <h1><b>ABC</b> Digital</h1>
            </section>
            <div className="BlogTitle--detail">
                {
                    pageSetting.categories != undefined ? <p>Categoria: {pageSetting.categories}</p> : ""
                }
                {
                    pageSetting.tags != undefined ? <p>Etiqueta: {pageSetting.tags}</p> : ""
                }
            </div>
        </>
     );
        
        
}
 
export default BlogTitle;