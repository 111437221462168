import React from "react";
import "./BlogItem.scss";
import Logo from "../../../images/Logo.png";

const BlogItem = ({article}) => {

    const dec = {
        '&quot;': '"',
        '&hellip;': '...'
    };

    const titleInterpreter = (str) => {

        let data ;

        if(str.includes("|")){
            data = str.split("|")[0];
        }else{
            data = str;
        }

        if(data.split(' ').length > 5){
            data = data.split(' ').slice(0, 5).join(' ') + '...';
        }

        return data;
    }

    const decodeHtmlEntity = (str) => {
       let data;

       // Itera sobre cada clave en el diccionario 'dec'
       for (let key in dec) {
           // Crea una nueva expresión regular con la clave actual
           let regex = new RegExp(key, 'g');
           // Reemplaza todas las instancias de la clave en 'str' con el valor correspondiente en 'dec'
           str = str.replace(regex, dec[key]);
       }
       // Devuelve la cadena 'str' modificada


       // Si str tiene mas de 20 palabras, corta el string y agrega '...'
         if (str.split(' ').length > 20) {
              str = str.split(' ').slice(0, 20).join(' ') + '...';
            }

       return str;

    };


  return (
    <div className="section_our_solution">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="our_solution_category">
            <div className="solution_cards_box">
              <div className="solution_card">
                <div className="hover_color_bubble"></div>
                <div className="so_top_icon">
                 <img src={Logo} alt="Logo" />
                </div>
                <div className="solu_title">
                  <div>{titleInterpreter(article.title)}</div>
                </div>
                <div className="solu_description">
                  <p>
                  {decodeHtmlEntity(article.excerpt)}
                  </p>
                  <a href={article.link}>
                  <button className="read_more_btn" type="button">
                    Leer más
                  </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
