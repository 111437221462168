import React, { useEffect, useState } from 'react';
import ArticleItem from './ArticleItem';
import BlogItem from './BlogItem';
import BlogTitle from './BlogTitle';

const ArticleList = () => {

    const [article_list, setArticleList] = useState(post_list);

    return (
        <div>
            <BlogTitle />
            <div className='article-list row'>

                {            
                    article_list.map((article, index) =>  {
                        return <div className='col-4' > <BlogItem article={article} /> </div>
                    })                
                }
                
            </div>
        </div>
    );
}

export default ArticleList;

