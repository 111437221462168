import './ArticleSingle.scss';
import React from 'react';


const ArticleSingle = () => {

    return (
        <div className='article-single'>
            <h1>{post_title}</h1>
            
                { post_thumbnail ? <picture><img src={post_thumbnail}/> </picture> : ""}
            
            <div className='category-list' dangerouslySetInnerHTML={{__html: post_category}} ></div>

            <article dangerouslySetInnerHTML={{ __html: post_content.innerHTML }} />
        </div>
    );
}

export default ArticleSingle;
