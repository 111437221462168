import React from 'react';
import { render } from 'react-dom';
import ArticleList from './componentes/ArticleList';
import ArticleSingle from './componentes/ArticleSingle';





const renderApp = (id, component) => {
    const element = document.getElementById(id);

    if (element) {
        render(component, element);
    }
}

renderApp('abc-digital-content', <ArticleList />);

renderApp('abc-digital-content-single', <ArticleSingle />);

renderApp('abc-digital-content-archive', <ArticleList />);


